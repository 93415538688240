<template>
  <div class="main-page">
    <section class="page-section mb-3">
      <div class="container-fluid">
        <div class="grid justify-content-center">
          <div class="col-12 sm:col-6 md:col-3 comp-grid">
            <div class="card nice-shadow-18">
				 <div class="text-center mt-1 mb-4">
                <router-link to="/" class="flex-grow-none">
                  <img
                    class="animate__animated animate__swing mt-2"
                    src="images/logo.png"
                    alt="logo"
                    style="height: 50px"
                  />
                  <div class="text-primary text-sm">for GTEC</div>
                </router-link>
              </div>
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startLogin()"
                  @reset="resetForm"
                >
                  <div class="mb-2 w-full">
                    <InputText
                      label="Username Or Email"
                      placeholder="Username Or Email"
                      class="w-full"
                      v-model.trim="formData.username"
                      required="required"
                      type="text"
                    />
                  </div>
                  <div class="mb-2 w-full">
                    <Password
                      label="Password"
                      inputClass="w-full"
                      :feedback="false"
                      toggleMask
                      class="w-full"
                      placeholder="Password"
                      required="required"
                      v-model="formData.password"
                    />
                  </div>
                  <div
                    class="flex justify-content-between align-items-center my-2"
                  >
                    <div class="flex align-items-center">
                      <Checkbox
                        class="mr-2"
                        id="rememberme"
                        :binary="true"
                        v-model="rememberUser"
                      />
                      <label class="text-sm text-500" for="rememberme"
                        >Remember Me</label
                      >
                    </div>
                    <div style="width: auto">
                      <router-link to="/index/forgotpassword"
                        ><Button
                          class="p-button-danger p-button-text"
                          label="Reset Password?"
                      /></router-link>
                    </div>
                  </div>
                  <transition
                    appear
                    enter-active-class="animated bounceIn"
                    v-if="errorMsg"
                    leave-active-class="animated fadeOut"
                  >
                    <Message
                      severity="error"
                      :closable="false"
                      @close="errorMsg = ''"
                      >{{ errorMsg.toString() }}</Message
                    >
                  </transition>
                  <div class="text-center">
                    <Button
                      label="Login"
                      :loading="loading"
                      icon="pi pi-lock-open"
                      class="w-full"
                      type="submit"
                    >
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { PageMixin } from "../../mixins/page.js";
export default {
  name: "IndexComponent",
  components: {},
  mixins: [PageMixin],
  data: function () {
    return {
      formData: {
        username: "",
        password: "",
      },
      rememberUser: false,
      loading: false,
      ready: false,
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    ...mapActions("auth", ["login"]),
    startLogin() {
      this.loading = true;
      this.errorMsg = "";
      let formData = this.formData;
      let rememberUser = this.rememberUser;
      let url = "/auth/login";
      let payload = {
        formData,
        rememberUser,
        url,
      };
      this.login(payload).then(
        (response) => {
          this.loading = false;
          let loginData = response.data;
          if (loginData.token) {
            this.$router.go();
            //let nextUrl = this.$route.query.nexturl || '/home';
            //location.href= "#" + nextUrl;
            //location.reload();
          } else {
            this.$router.push(loginData.nextpage);
          }
        },
        (request) => {
          this.loading = false;
          this.errorMsg =
            request.response?.data || "Unable to establish connection...";
        }
      );
    },
    resetForm() {
      this.formData = {
        username: "",
        password: "",
      };
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
  },
  async mounted() {
    this.ready = true;
  },
  async created() {},
};
</script>
<style></style>
